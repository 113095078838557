import { Controller } from "stimulus"

export default class extends Controller {
    initialize(){
        $('.player-select').select2({
            width: '100%',
        });
        $('.date-select').select2({
            placeholder: "날짜 선택",
            width: '100%',
        });
        $('.pitch-select').select2({
            placeholder: "투구 선택",
            width: '100%',
        });
    }

    connect(){
        $('.player-select').on("select2:select", function(event)  {
            const wrapper = event.target.closest(".pitching-sector");
            var playerID = $(event.target).find(':selected').val();
            fetch(`getDates?player_id=${playerID}`)
            .then(resp => resp.json())
            .then(data => {
            setDates(wrapper, data);
            })
        })
        $('.date-select').on("select2:select", function(event)  {
            const wrapper = event.target.closest(".pitching-sector");
            var playerID = wrapper.querySelector('.player-select').value
            var date = $(event.target).find(':selected').val();
            fetch(`getPitches?date=${date}&player_id=${playerID}`)
            .then(resp => resp.json())
            .then(data => {
            setPitches(wrapper, data);
            })
        })
        $('.pitch-select').on("select2:select", function(event)  {
            const wrapper = event.target.closest(".pitching-sector");
            var pitchUID = $(event.target).find(':selected').val();
            fetch(`getPitchData?pitch_uid=${pitchUID}`)
            .then(resp => resp.json())
            .then(data => {
            setPitchData(wrapper, data);
            })
        })
    }
}

function setDates(wrapper, dateList){
    let dateSelect = wrapper.querySelector('.date-select')
    dateSelect.innerHTML = ''
    let pitchSelect = wrapper.querySelector('.pitch-select')
    pitchSelect.innerHTML = ''
    var placeHolder = document.createElement('option')
    placeHolder.value = null;
    placeHolder.innerHTML = '날짜 선택';
    dateSelect.append(placeHolder)
    for (let item of dateList['pitchDates']) {
  
        var opt = document.createElement('option');
        opt.value = item;
        opt.innerHTML = item;
        dateSelect.appendChild(opt)
    }
}

function setPitches(wrapper, pitchList){
    let pitchSelect = wrapper.querySelector('.pitch-select')
    pitchSelect.innerHTML = ''
    var placeHolder = document.createElement('option')
    placeHolder.value = null;
    placeHolder.innerHTML = '투구 선택';
    pitchSelect.append(placeHolder)
    for (let [index, item] of pitchList['pitches'].entries()) {
        var opt = document.createElement('option');
        opt.value = item["pitch_uid"];
        opt.innerHTML = `${index+1}구 - ${item['pitch_type']}`;
        pitchSelect.appendChild(opt)
    }
}

function setPitchData(wrapper, pitchData){
    let pitchSelect = wrapper.querySelector('.pitch-select')

    pitchSelect.append(placeHolder)
    for (let [index, item] of pitchList['pitches'].entries()) {
        var opt = document.createElement('option');
        opt.value = item["pitch_uid"];
        opt.innerHTML = `${index+1}구 - ${item['pitch_type']}`;
        pitchSelect.appendChild(opt)
    }
}