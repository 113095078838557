import { Controller } from "stimulus"

export default class extends Controller {
    static targets = [ "links", "template" ]

    buttonSecterMajor = document.getElementById('buttonSecterMajor');
    buttonSecterMinor = document.getElementById('buttonSecterMinor');
    buttonSecterRehab = document.getElementById('buttonSecterRehab');

    secterMajor = document.getElementById('sectorMajor');
    secterMinor = document.getElementById('sectorMinor');
    secterRehab = document.getElementById('sectorRehab');

    initialize(){
        $('.player-select').select2({
            width: '100%',
            templateResult: formatRepo,
            templateSelection: formatRepoSelection,
            ajax: {
                url: '/reports/injury_reports/getTeamPlayers',
                dataType: 'json',
                delay: 250,
                data: function (params) {
                    var query = {
                        search: params.term
                    }
                    return query;
                },
                processResults: function (data) {
                    return {
                        results: data.players,
                };
                },
                cache: true
            },
        });
        $('.level-select').select2({
            width: '100%',
        });
        $('.injury-report-current-state').each(function(){
            //scroll height가 client height의 길이를 뛰어넘는 지점에서
            // if (elem.clientHeight < elem.scrollHeight)
            if(this.clientHeight < this.scrollHeight){
              $(this).height(this.scrollHeight)
            }
        })
        $('.injury-report-etc').each(function(){
            //scroll height가 client height의 길이를 뛰어넘는 지점에서
            // if (elem.clientHeight < elem.scrollHeight)
            if(this.clientHeight < this.scrollHeight){
              $(this).height(this.scrollHeight)
            }
        })
    }

    // show record sector
    showSectorMajor(event){
        if ($('#buttonSecterMajor').hasClass('bg-black text-white')==false){
            this.buttonSecterMajor.classList.replace('bg-white', 'bg-black');
            this.buttonSecterMajor.classList.replace('text-black', 'text-white');
        }

        this.buttonSecterMinor.classList.replace('bg-black', 'bg-white');
        this.buttonSecterMinor.classList.replace('text-white', 'text-black');

        this.buttonSecterRehab.classList.replace('bg-black', 'bg-white');
        this.buttonSecterRehab.classList.replace('text-white', 'text-black');

        this.secterMajor.style.display = 'block';
        this.secterMinor.style.display = 'none';
        this.secterRehab.style.display = 'none';

        $('.injury-report-current-state').each(function(){
            $(this).css('height', 'auto' );
            $(this).height(this.scrollHeight)
        })
        $('.injury-report-etc').each(function(){
            $(this).css('height', 'auto' );
            $(this).height(this.scrollHeight)
        })

        // let data = { 
        //     "player_id":this.player_id,
        //     "kind" : 'show',
        //     "sector": 'scouting',
        // }
        // setLogs(data)
    }

    // show record sector
    showSectorMinor(event){
        if ($('#buttonSecterMinor').hasClass('bg-black text-white')==false){
            this.buttonSecterMinor.classList.replace('bg-white', 'bg-black');
            this.buttonSecterMinor.classList.replace('text-black', 'text-white');
        }

        this.buttonSecterMajor.classList.replace('bg-black', 'bg-white');
        this.buttonSecterMajor.classList.replace('text-white', 'text-black');

        this.buttonSecterRehab.classList.replace('bg-black', 'bg-white');
        this.buttonSecterRehab.classList.replace('text-white', 'text-black');

        this.secterMajor.style.display = 'none';
        this.secterMinor.style.display = 'block';
        this.secterRehab.style.display = 'none';

        $('.injury-report-current-state').each(function(){
            $(this).css('height', 'auto' );
            $(this).height(this.scrollHeight)
        })
        $('.injury-report-etc').each(function(){
            $(this).css('height', 'auto' );
            $(this).height(this.scrollHeight)
        })

        // let data = { 
        //     "player_id":this.player_id,
        //     "kind" : 'show',
        //     "sector": 'scouting',
        // }
        // setLogs(data)
    }

    // show record sector
    showSectorRehab(event){
        if ($('#buttonSecterRehab').hasClass('bg-black text-white')==false){
            this.buttonSecterRehab.classList.replace('bg-white', 'bg-black');
            this.buttonSecterRehab.classList.replace('text-black', 'text-white');
        }

        this.buttonSecterMajor.classList.replace('bg-black', 'bg-white');
        this.buttonSecterMajor.classList.replace('text-white', 'text-black');

        this.buttonSecterMinor.classList.replace('bg-black', 'bg-white');
        this.buttonSecterMinor.classList.replace('text-white', 'text-black');

        this.secterMajor.style.display = 'none';
        this.secterMinor.style.display = 'none';
        this.secterRehab.style.display = 'block';

        $('.injury-report-current-state').each(function(){
            $(this).css('height', 'auto' );
            $(this).height(this.scrollHeight)
        })
        $('.injury-report-etc').each(function(){
            $(this).css('height', 'auto' );
            $(this).height(this.scrollHeight)
        })

        // let data = { 
        //     "player_id":this.player_id,
        //     "kind" : 'show',
        //     "sector": 'scouting',
        // }
        // setLogs(data)
    }

    connect() { 
        $(window).on('resize', function(){
            $('.injury-report-current-state').each(function(){
                $(this).css('height', 'auto' );
                $(this).height(this.scrollHeight)
            })
            $('.injury-report-etc').each(function(){
                $(this).css('height', 'auto' );
                $(this).height(this.scrollHeight)
            })
        });
    }

    setHeight(event){
        const wrapper = event.target
        wrapper.style.height = 'auto'
        wrapper.style.height=wrapper.scrollHeight+"px"
    }

    add_player(event) {
        const content = this.templateTarget.innerHTML.replace(/NEW_INJURY/g, new Date().getTime())
        this.linksTarget.insertAdjacentHTML("beforebegin", content)
        $('.player-select').select2({
            width: '100%',
            templateResult: formatRepo,
            templateSelection: formatRepoSelection,
            ajax: {
                url: '/reports/injury_reports/getTeamPlayers',
                dataType: 'json',
                delay: 250,
                data: function (params) {
                    var query = {
                        search: params.term
                    }
                    return query;
                },
                processResults: function (data) {
                    return {
                        results: data.players,
                };
                },
                cache: true
            },
        });
    }

    remove_player(event) {
        const wrapper = event.target.closest(".injury-report-date")
        if (wrapper.dataset.newRecord == "true") {
            wrapper.remove()
        }
        else {
            wrapper.querySelector("input[name*='_destroy']").value = 1
            wrapper.style.display = "none"
        }
    }

    populateData(event){
        let level_id = $("#level_id").val()
        $.ajax({
            url: '/reports/injury_reports/getYesterdayReports',
            method: 'POST',
            async: false,
            data: { "level_id": level_id},
            success: function (data){
                // Remove default empty template -- it will be replaced in loop below
                $(".injury-report-date").remove();

                const linkTarget = $("#injury-report-date-links")[0];
                var injury_count = 10
                // Loop through all the players and fill in the form data
                $('#location').val(data.yesterday_report.location)
                data.yesterday_report.forEach(player => {
                    //const now = new Date().getTime();
                    const idTag = `injury_report_injury_players_attributes_${String(injury_count)}`;
                    const content = $("#injury-report-date-template-target")[0].innerHTML.replace(
                    /NEW_INJURY/g,
                    String(injury_count)
                    );
                    linkTarget.insertAdjacentHTML("beforebegin", content);

                    $(`#${idTag}_position`).val(player.position);
                    // $(`#${idTag}_player_name`).val(player.player_name);
                    $(`#${idTag}_player_id`).select2({
                        width: '100%',
                        templateResult: formatRepo,
                        templateSelection: formatRepoSelection,
                        ajax: {
                            url: '/reports/injury_reports/getTeamPlayers',
                            dataType: 'json',
                            delay: 250,
                            data: function (params) {
                                var query = {
                                    search: params.term
                                }
                                return query;
                            },
                            processResults: function (data) {
                                return {
                                    results: data.players,
                                };
                            },
                            cache: true
                        },
                    });
                    $(`#select2-${idTag}_player_id-container`).text(player.title);
                    $(`#select2-${idTag}_player_id-container`).prop('title', player.title);
                    let option = $(`<option value='${player.player_id}' title='${player.title}'></option>`);
                    $(`#${idTag}_player_id`).append(option);
                    $(`#${idTag}_player_id`).val(player.player_id);
                    $(`#${idTag}_injury_date`).val(player.injury_date);
                    $(`#${idTag}_injury_field_explain`).val(player.injury_field_explain);
                    $(`#${idTag}_current_state`).val(player.current_state);
                    $(`#${idTag}_training_etc`).val(player.training_etc);
                    injury_count = injury_count + 1
                });                
            },
        });
    }

    createPDF(event){

        var level_id=event.target.attributes['level'].value
        var position=event.target.attributes['position'].value
        var date=event.target.attributes['date'].value

        fetch(`/reports/injury_reports/getInjuryReports?date=${date}&level_id=${level_id}&position=${position}`)
        .then(resp => resp.json())
        .then(data => {
            this.injuryReports = data['injury_reports'];
            if(position=='pitcher'){
                this.setInjuryReportPDFContents('pitcher');
            } else {
                this.setInjuryReportPDFContents('batter');
            }
            
        });
    }
    
    setInjuryReportPDFContents(position){
        var date = this.injuryReports['date']
        var level = this.injuryReports['level']
        var position = this.injuryReports['position']
        var injuryPlayers = this.injuryReports['injury_players']
        var reportHTML = `
                            <head>
                                <script src="https://cdn.tailwindcss.com"></script>
                            </head>
                            <body>
                                <div class="h-[210mm] w-full px-12" id="injuryReport">
                                    <div class='inline-flex items-center justify-center w-full'>
                                        <hr class="w-full h-1 my-8 bg-gray-200 border-0 rounded">
                                        <h2 class="w-auto px-2 absolute bg-white text-2xl">
                                            ${date} ${level} ${position} 부상일지
                                        </h2>
                                    </div>

                                    <div class='h-2'></div>

                                    <div id='injuryPlayers' class="w-full grid grid-cols-16"> </div>

                                </div>
                            </body>
                        `

        var el = document.createElement( 'html' );
        el.innerHTML = `<html>${reportHTML}</html>`;
        let reportBody = el.querySelector('#injuryPlayers')
        var strHTML = `
                        <div class="col-span-2 border-t border-l border-r border-gray-500 bg-blue-50 inline-block text-md text-center p-1 font-bold">이름</div>
                        <div class="col-span-2 border-t border-l border-r border-gray-500 bg-blue-50 text-md text-center p-1 font-bold">부상일시</div>
                        <div class="col-span-3 border-t border-l border-r border-gray-500 bg-blue-50 text-md text-center p-1 font-bold">부상부위 설명</div>
                        <div class="col-span-6 border-t border-l border-r border-gray-500 bg-blue-50 text-md text-center p-1 font-bold">현재 상황</div>                        
                        <div class="col-span-3 border-t border-l border-r border-gray-500 bg-blue-50 text-md text-center p-1 font-bold">비고</div>
                        
                    `;
        for (let [index, injuryPlayer] of injuryPlayers.entries()){
            let playerHTML = `
                            <div class="col-span-2 border-t border-l border-r ${index=injuryPlayers.length-1 ? "border-b" : ''} border-gray-500 items-center text-xs text-center p-1 font-bold">${injuryPlayer['title']}</div>
                            <div class="col-span-2 border-t border-l border-r ${index=injuryPlayers.length-1 ? "border-b" : ''} border-gray-500 items-center text-xs text-center p-1 font-bold">${injuryPlayer['injury_date']}</div>
                            <div class="col-span-3 border-t border-l border-r ${index=injuryPlayers.length-1 ? "border-b" : ''} border-gray-500 text-xs text-center p-1 font-bold">${injuryPlayer['injury_field_explain']}</div>
                            <div class="col-span-6 border-t border-l border-r ${index=injuryPlayers.length-1 ? "border-b" : ''} border-gray-500 text-xs text-center p-1 font-bold">${injuryPlayer['current_state']}</div>
                            <div class="col-span-3 border-t border-l border-r ${index=injuryPlayers.length-1 ? "border-b" : ''} border-gray-500 text-xs text-center p-1 font-bold">${injuryPlayer['training_etc']}</div>
                            `;
            strHTML+=playerHTML;
        }
        reportBody.insertAdjacentHTML( 'beforeend', strHTML );

        var reportElement = el.getElementsByTagName('body')[0]
        html2pdf().set({
            margin: [5, 0, 5, 0],
            pagebreak: { mode: 'avoid-all' },
            jsPDF: { orientation: 'landscape', unit: 'mm', format: 'a4' },
          }).from(reportElement).toPdf().save(`${date} ${level} ${position} 부상일지.pdf`)
    
    }
    
}

function formatRepo (repo) {
    if (repo.loading) {
      return repo.text;
    }
  
    var $container = $(
  
      "<div class='flex items-center gap-1 p-1'>"+
        "<img class='w-16 h-16' src='" + repo.thumnail + "'>"+
        "<div class='flex flex-col'>"+
            '<strong class="text-slate-900 text-sm font-medium dark:text-slate-200 select2-result-repository__title"></strong>'+
            '<span class="text-slate-500 text-sm font-medium dark:text-slate-400 select2-result-repository__description"></span>'+
        "</div>"+
      "</div>"
    );
  
    $container.find(".select2-result-repository__title").text(repo.title);
    $container.find(".select2-result-repository__description").text(repo.text);
  
    return $container;
}

function formatRepoSelection (repo) {
    return repo.full_name || repo.title;
}