// Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction
//
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>
import { Controller } from "stimulus"

export default class extends Controller {
    initialize(){
      $(document).ready(function() {
        $('.player-select').select2({
          width: '100%',
          templateResult: formatRepo,
          templateSelection: formatRepoSelection,
          ajax: {
            url: '/interviews/each_player_interviews/getTeamPlayers',
            dataType: 'json',
            delay: 250,
            data: function (params) {
              var query = {
                  search: params.term
              }
              return query;
            },
            processResults: function (data) {
              return {
                results: data.players,
              };
            },
            cache: true
          },
        });
        $('.interview-content').each(function(){
          //scroll height가 client height의 길이를 뛰어넘는 지점에서
          // if (elem.clientHeight < elem.scrollHeight)
          if(this.clientHeight < this.scrollHeight){
            $(this).height(this.scrollHeight)
          }
        })
      })
    }
    connect(){
      $(window).on('resize', function(){
        $('.interview-content').each(function(){
          $(this).css('height', 'auto' );
          $(this).height(this.scrollHeight)
        })
      });
      $('#each_player_interview_form').on('submit',function(event){
        var isReturn=true
        var interview_date = $('#interview_date').val()
        var interview_location = $('#each_player_interview_interview_location').val()
        var player_id = $('#each_player_interview_player_id').val()
    
        if (interview_date=="" | interview_location=="" | player_id==""){
          if (interview_date==""){
            alert('날짜를 선택해주세요.')
            event.preventDefault()
          }
          else if(player_id==""){
            alert('선수를 입력해주세요.')
            event.preventDefault()
          }
          else{
            alert('장소를 입력해주세요.')
            event.preventDefault()
          }
          isReturn=false
        }else{
          $.ajax({
            url: '/interviews/each_player_interviews/check_duplicate',
            method: 'POST',
            async: false,
            data: { "player_id": player_id, "interview_date": interview_date},
            success: function (data){
              if(data['duplicate']>=1){
                alert('같은 날짜의 면담일지가 이미 존재합니다.')
                event.preventDefault()
                isReturn=false
              }else{
                alert('작성 완료')
              }
            },
          }); 
        }

        return isReturn
        
      })
      $('#each_player_interview_edit_form').on('submit',function(){
        alert('수정 완료')
      })
    }

    setHeight(event){
        const wrapper = event.target
        wrapper.style.height = 'auto'
        wrapper.style.height=wrapper.scrollHeight+"px"
    }
}
function formatRepo (repo) {
  if (repo.loading) {
    return repo.text;
  }

  var $container = $(

    "<div class='flex items-center gap-1 p-1'>"+
      "<img class='w-16 h-16' src='" + repo.thumnail + "'>"+
      "<div class='flex flex-col'>"+
          '<strong class="text-slate-900 text-sm font-medium dark:text-slate-200 select2-result-repository__title"></strong>'+
          '<span class="text-slate-500 text-sm font-medium dark:text-slate-400 select2-result-repository__description"></span>'+
      "</div>"+
    "</div>"
  );

  $container.find(".select2-result-repository__title").text(repo.title);
  $container.find(".select2-result-repository__description").text(repo.text);

  return $container;
}

function formatRepoSelection (repo) {
  return repo.full_name || repo.title;
}