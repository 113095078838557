import { Controller } from "stimulus"

export default class extends Controller {

    initialize(){
        $('.player-select').select2({
            width: '100%',
            ajax: {
                url: '/scouts/international_scout_comments/get_international_players',
                dataType: 'json',
                delay: 250,
                data: function (params) {
                    var query = {
                        search: params.term
                    }
                    return query;
                },
                processResults: function (data) {
                    return {
                    results: data.players,
                    };
                },
                cache: true
            },
        });
        $('.level-select').select2({
            width: '100%',
        });
        $('.injury-report-current-state').each(function(){
            //scroll height가 client height의 길이를 뛰어넘는 지점에서
            // if (elem.clientHeight < elem.scrollHeight)
            if(this.clientHeight < this.scrollHeight){
              $(this).height(this.scrollHeight)
            }
        })
        $('.injury-report-etc').each(function(){
            //scroll height가 client height의 길이를 뛰어넘는 지점에서
            // if (elem.clientHeight < elem.scrollHeight)
            if(this.clientHeight < this.scrollHeight){
              $(this).height(this.scrollHeight)
            }
        })
    }
    connect() { 
        $(window).on('resize', function(){
            $('.injury-report-current-state').each(function(){
                $(this).css('height', 'auto' );
                $(this).height(this.scrollHeight)
            })
            $('.injury-report-etc').each(function(){
                $(this).css('height', 'auto' );
                $(this).height(this.scrollHeight)
            })
        });

        $('#injury_report_date_form').on('submit',function(){
            var is_return = true;
            var current_input_date = $('#injury_date').val()
            var current_input_league = $('#level_id').val()
            var current_input_location = $('#venue_id').val()
        
            if (current_input_date=="" | current_input_league=="" | current_input_location==""){
                if (current_input_date==""){
                  alert('날짜를 선택해주세요.')
                }
                else if(current_input_league==""){
                  alert('군위치를 입력해주세요.')
                }
                else{
                  alert('장소를 입력해주세요.')
                }        
                is_return = false
            }else{
              $('.injury-date-contents').each(function(index){
                
                var current_injury_player = $('.injury-player').eq(index).val()
                var current_injury_date = $('.injury-date').eq(index).val()
                var current_injury_position = $('.injury-position').eq(index).val()
                
                if (current_injury_player=="" | current_injury_date=="" | current_injury_position==""){
                  if (current_injury_player==""){
                    alert(`${index+1}번 선수의 이름을 입력해주세요.`)
                  }
                  else if(current_injury_date==""){
                    alert(`${index+1}번 선수의 부상날짜를 확인해주세요.`)
                  }
                  else{
                    alert(`${index+1}번 선수의 포지션을 확인해주세요.`)
                  }
                  is_return=false
                }
              });
            }
            return is_return
          })

    }
    setHeight(event){
        const wrapper = event.target
        wrapper.style.height = 'auto'
        wrapper.style.height=wrapper.scrollHeight+"px"
    }
}